import { ReactNode } from 'react';
import { ClientOnly } from 'components/_shared/widgets/render-control';
import { createPortal } from 'react-dom';

interface PortalProps {
  children: ReactNode;
  target?: HTMLElement;
}

const Portal = ({ children, target = document.body }: PortalProps) =>
  createPortal(children, target);

const Wrapper = (props: PortalProps) => (
  <ClientOnly>
    <Portal {...props} />
  </ClientOnly>
);

export default Wrapper;
